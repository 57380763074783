

    import { Component, Vue } from 'vue-property-decorator';
    import { UserInfo, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
    import {
        addDoc, collection, doc, getDoc, setDoc,
    } from 'firebase/firestore';
    import { auth, firestore } from '@/firebase';

    // db wish interface
    interface WishI {
        id?: string,
        title: string,
        url: string,
        img: string,
        price: number,
        category: string,
        reserved?: boolean,
        note: string,
    }


    @Component
    export default class Admin extends Vue {

        authUser: UserInfo | null = null;
        email: string = '';
        pass: string = '';
        isWishEdit = false;
        wishId: string | null = null;

        title: string = '';
        url: string = '';
        imgUrl: string = '';
        category: string = '';
        reserved: boolean = false;
        price: string = '';
        note: string = '';

        created() {
            if (this.$route.query.wishid) {
                this.wishId = this.$route.query.wishid;
                this.isWishEdit = true;

                this.setDocumentToEdit(this.$route.query.wishid);
            }

            onAuthStateChanged(auth, (user) => {
                this.authUser = user;
            });
        }

        async setDocumentToEdit(wishId: string) {
            const docRef = doc(collection(firestore, 'wishlist'), wishId);
            const document = await getDoc(docRef);

            this.title = document.data()?.title || '';
            this.url = document.data()?.url || '';
            this.imgUrl = document.data()?.imgUrl || '';
            this.category = document.data()?.category || '';
            this.reserved = document.data()?.reserved;
            this.price = document.data()?.price || '0';
            this.note = document.data()?.note || '';

            await this.$nextTick();
        }

        loginUser() {
            signInWithEmailAndPassword(auth, this.email, this.pass)
                .then(() => {
                    this.$router.push('/');
                }).catch((e) => {
                    alert('Nepodarilo sa mi ta prihlasit.');
                });
        }

        handleSubmit() {
            if (this.isWishEdit) {
                this.editWish();
                return;
            }
            this.addWish();
        }

        editWish() {
            const data: WishI = {
                title: this.title,
                url: this.url,
                img: this.imgUrl,
                category: this.category,
                reserved: this.reserved,
                note: this.note,
                price: parseFloat(this.price),
            };

            const docRef = doc(collection(firestore, 'wishlist'), this.wishId || '');
            setDoc(docRef, data).then(() => {
                (window as any).M.toast({ html: 'Gratulujem! Tvoj wish sa upravil ;) ' });
            }).catch((e) => {
                console.log(e);
                alert('Nepodarilo sa uložiť wish :(');
            });
        }

        addWish() {
            const data: WishI = {
                title: this.title,
                url: this.url,
                img: this.imgUrl,
                category: this.category,
                reserved: false,
                note: this.note,
                price: parseFloat(this.price),
            };

            addDoc(collection(firestore, 'wishlist'), data).then(() => {
                (window as any).M.toast({ html: 'Gratulujem! Tvoj wish sa pridal ;) ' });
                this.price = '';
                this.title = '';
                this.imgUrl = '';
                this.url = '';
                this.category = '';
                this.note = '';
            }).catch(() => {
                // eslint-disable-next-line no-alert
                alert('Nepodarilo sa pridat wish :(');
            });
        }
    }

